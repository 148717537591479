<template>
  <a-modal
    title="数据授权"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="formLoading">
      <a-form :form="form">
        <a-form-item
          label="权限范围"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-select
            v-decorator="['dataScope', {rules: [{required: true, message: '请选择权限范围！'}]}]"
            @change="handleChange"
          >
            <a-select-option v-for="item in dataRange" :key="item.dataScope" :value="item.dataScope">
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-spin :spinning="loading" v-show="bool">
            <a-tree
              default-expand-all
              class="tree"
              v-model="customCurrent"
              checkable
              :tree-data="customRange"
              :check-strictly="true"
              :key="treeDataKey"
              :replaceFields="{children:'children', title:'name', key:'key' }"
            />
          </a-spin>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { getDeptByRoleId, dataAccredit, getOne } from '@/api/user-rights/rolemanage'

export default {
  name: 'AcRoleAuthorize',
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      // 数据权限范围
      dataRange: [],
      // form申请单
      form: this.$form.createForm(this),
      // 自定义数据范围
      customRange: [],
      // 自定义选中的数据
      customCurrent: { checked: [] },
      formLoading: false,
      loading: false,
      // 是否隐藏
      bool: false,
      treeDataKey: 1
    }
  },
  methods: {
    /**
     *编辑
     */
    edit (record) {
      this.roleId = record.roleId
      // 授权范围
      this.createData()
      // 单条信息
      this.handleInfo()
      this.visible = true
    },
    /**
     * 选择后的判断
     */
    handleChange (value) {
      // 如果为自定义权限
      if (value === 4) {
        if (this.customRange < 1) {
          this.handleCustomTree()
        }
        this.bool = true
      } else {
        this.bool = false
      }
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          let data = Object.assign({}, { dataScope: values.dataScope, roleId: this.roleId })
          // 自定义加入选择
          if (values.dataScope === 4) {
            data = Object.assign(data, { deptIds: this.customCurrent.checked })
          }
          dataAccredit(data).then((res) => {
            this.confirmLoading = false
            if (res.code === 200) {
              this.$message.success('编辑成功')
              this.handleCancel()
              this.$emit('ok', values)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 获取信息
     */
    handleInfo () {
      this.formLoading = true
      getOne({ roleId: this.roleId }).then((res) => {
        if (res.code === 200) {
          if (res.data['dataScope'] === 4) {
            this.bool = true
            this.handleCustomTree()
          }
          this.form.setFieldsValue({
            dataScope: res.data['dataScope']
          })
        }
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.formLoading = false
      })
    },
    /**
     * 获取自定义树
     */
    handleCustomTree () {
      this.loading = true
      getDeptByRoleId({ roleId: this.roleId }).then((res) => {
        if (res.code === 200) {
          this.customRange = res.data['spDeptVoList'] // 树
          this.customCurrent.checked = res.data['deptIds'] // 选中
          this.treeDataKey++
        }
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     *构建数据
     */
    createData () {
      this.dataRange = [
        { label: '本人', dataScope: 0 },
        { label: '本部门数据权限', dataScope: 1 },
        { label: '本部门及以下数据权限', dataScope: 2 },
        { label: '全部数据权限', dataScope: 3 },
        { label: '自定数据权限', dataScope: 4 }
      ]
    },
    /**
     *取消
     */
    handleCancel () {
      this.form.resetFields()
      this.bool = false
      this.customRange = []
      this.customCurrent = { checked: [] }
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/modal";

.tree {
  background: #f5f5f557
}
</style>
