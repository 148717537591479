<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <a-col :md="8" :sm="24">
              <a-form-item label="角色名称">
                <a-input v-model="queryParam.roleName" placeholder="请输入角色名称" />
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item
                label="角色状态">
                <a-select
                  v-model="queryParam.status"
                  placeholder="请选择角色状态"
                >
                  <a-select-option :value="1">
                    正常
                  </a-select-option>
                  <a-select-option :value="0">
                    停用
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
              </span>
            </a-col>

          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" v-permission="['perm:role:add']" @click="$refs.actionForm.add()" icon="plus">新增</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :columns="columns"
        :data="loadData"
        :rowKey="(record) => record.id"
      >
        <span slot="status" slot-scope="status">
          <span v-if="status===1">
            正常
          </span>
          <span v-else>
            停用
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <!--管理员KYE admin-->
          <div v-if="record.roleKey !== 'admin'">
            <a @click="$refs.actionForm.edit(record)" v-permission="['perm:role:edit']">编辑</a>
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" v-permission="['perm:role:del','perm:role:menuScope','perm:role:dataScope']"> 更多 <a-icon type="down" /></a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => del(record)">
                    <a v-permission="['perm:role:del']">删除</a>
                  </a-popconfirm>
                  <a @click="()=>{$refs['menuAuthorize'].edit(record)}" v-permission="['perm:role:menuScope']">菜单授权</a>
                  <a @click="()=>{$refs['roleAuthorize'].edit(record)}" v-permission="['perm:role:dataScope']">数据授权</a>
                  <a @click="()=>{$refs['userAuthorize'].edit(record)}" v-permission="['perm:role:userScope']">用户授权</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </span>
      </s-table>

      <action-form ref="actionForm" @ok="handleOk" />
      <menu-authorize ref="menuAuthorize" @ok="handleOk" />
      <role-authorize ref="roleAuthorize" @ok="handleOk" />
      <user-authorize ref="userAuthorize" @ok="handleOk" />
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { page, del } from '@/api/user-rights/rolemanage'
import actionForm from './components/acRoleActionForm'
import roleAuthorize from './components/acRoleAuthorize'
import menuAuthorize from './components/acMenuAuthorize'
import userAuthorize from './components/acUserAuthorize'

export default {
  components: {
    STable,
    actionForm,
    roleAuthorize,
    menuAuthorize,
    userAuthorize
  },

  data () {
    return {
      advanced: false, // 高级搜索 展开/关闭
      queryParam: {}, // 查询参数
      columns: [ // 表头
        {
          title: '角色名称',
          dataIndex: 'roleName'
        },
        {
          title: '角色键名',
          dataIndex: 'roleKey'
        },
        {
          title: '角色状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '显示顺序',
          dataIndex: 'sort'
        },
        {
          title: '备注',
          dataIndex: 'description'
        },
        {
          title: '角色归属',
          dataIndex: 'handleUserName'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return page(Object.assign({pageNum: parameter.pageNo, pageSize: parameter.pageSize}, this.queryParam)).then((res) => {
          console.log(parameter, "parameter")
          res.data = res['rows']
          res.totalCount = res['total']
          res.pageNo = parameter['pageNo']
          return res
        })
      }
    }
  },

  methods: {
    del (record) {
      del({ roleId: record.roleId }).then((res) => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        }
      }).catch((err) => {
        console.error(err)
      })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style lang="less" scope>

.table-operator {
  margin-bottom: 2px;
}

button {
  margin-right: 8px;
}
</style>
